import type { FetchResponseType, Pagination, PaginationOption, PaginationParams, PaginationResponse, PaginationType } from './type'
import { PaginationStatus } from './type'
export * from './tools'
export type PaginationFn = ReturnType<typeof usePagination>
// export interface PaginationFn {
//   loadType: PaginationType
//   pagination: UnwrapNestedRefs<Pagination>
//   initPagination(pageNum?: number): void
//   setPagination<T>(data: PaginationResponse<T>): void
//   setPaginationStatus(status: PaginationStatus): void
//   canLoadMore(): boolean
//   loadPaginationData<P extends PaginationParams = PaginationParams, R = unknown>(
//     request: (params: P) => Promise<FetchResponseType<PaginationResponse<R>>>,
//     params: Omit<P, 'pageNum' | 'pageSize'> & Partial<Pick<P, 'pageNum' | 'pageSize'>>,
//     page?: number
//   ): Promise<{ response: FetchResponseType<PaginationResponse<R>>; list: R } | null>
// }
export type OmitPageParam<T extends PaginationParams> = Omit<T, 'pageSize' | 'pageNum'>
/**
 * 分页
 * TODO: 上拉请求 | 页码请求
 */
export function usePagination(option?: PaginationOption) {
  const loadType: PaginationType = option?.type || 'up'
  const currentPage = option?.pageNum || 1

  const pagination = reactive<Pagination>({
    page: currentPage, // 当前页数
    pageSize: option?.pageSize || 10, // 每页数
    itemCount: 0, // 总条数
    pageCount: 0, // 总页数
    status: PaginationStatus.INIT,
  })

  function initPagination(pageNum = 1) {
    setPage(pageNum)
    pagination.pageSize = option?.pageSize || 10
    pagination.itemCount = 0
    pagination.pageCount = 0
    pagination.status = PaginationStatus.INIT
  }

  function setPagination<T = unknown>(data: PaginationResponse<T>) {
    setPage(data.pageNum)
    pagination.pageSize = data.pageSize
    pagination.itemCount = data.total
    pagination.pageCount = data.pages
  }

  // 设置分页请求状态
  function setPaginationStatus(status: PaginationStatus) {
    pagination.status = status
  }
  // 判断是否能够加载更多
  function canLoadMore() {
    // 上拉加载时
    const statusCanLoadMore = !![PaginationStatus.INIT, PaginationStatus.MORE, PaginationStatus.ERROR].includes(pagination.status)
    // 页码分页
    const pageCanLoadMore = pagination.page <= pagination.pageCount || [PaginationStatus.INIT, PaginationStatus.ERROR].includes(pagination.status)

    // console.log(statusCanLoadMore, pageCanLoadMore, pagination.value.status)
    return loadType === 'up' ? (statusCanLoadMore && pageCanLoadMore) : pageCanLoadMore
  }

  function setPage(page = 1) {
    pagination.page = page
  }

  async function loadPaginationData<P extends PaginationParams = PaginationParams, R = unknown>(
    request: (params: P) => Promise<FetchResponseType<PaginationResponse<R>>>,
    params: Omit<P, 'pageNum' | 'pageSize'> & Partial<Pick<P, 'pageNum' | 'pageSize'>>,
    page?: number,
  ): Promise<{ response: FetchResponseType<PaginationResponse<R>>; list: R } | null> {
    // 如果有页码传递进来，设置页码
    if (loadType !== 'up')
      page && setPage(page)
      // 如果是上拉加载，当加载错误是不修改当前页码
    else if (pagination.status !== PaginationStatus.ERROR)
      page && setPage(page)

    if (!canLoadMore())
      // return Promise.reject(new Error(`不能加载更多数了(${pagination.value.status})`))
      return null

    const noAddPageCount = loadType !== 'up' || [PaginationStatus.INIT, PaginationStatus.ERROR].includes(pagination.status)
    try {
      const page = noAddPageCount ? pagination.page : pagination.page + 1
      setPage(page)
      // 设置 loading 状态
      setPaginationStatus(PaginationStatus.LOADING)
      // 发送请求
      const response = await request({ pageSize: pagination.pageSize, ...params, pageNum: page } as P)

      const { data } = response
      // console.log(data, 'datadata', params)

      // 设置分页状态
      setPaginationStatus(data.pageNum < data.pages ? PaginationStatus.MORE : PaginationStatus.NO_MORE)
      // 设置分页具体信息
      setPagination(data)

      return {
        response,
        list: response.data.list,
      }
    }
    catch (error) {
      console.error('loadPaginationData error: ', error)
      setPaginationStatus(PaginationStatus.ERROR)
      return Promise.reject(error)
      // throw error
    }
  }

  return {
    loadType,
    pagination,
    setPage,
    initPagination,
    setPagination,
    setPaginationStatus,
    canLoadMore, // 判断是否能够加载更多
    loadPaginationData,
  }
}
